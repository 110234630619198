<template>
  <div>
    <v-card>
      <v-card-title>
        <ButtonTopNavigationBack></ButtonTopNavigationBack>

        <h3 class="ml-3">
          Modifier une certification
        </h3>
      </v-card-title>
      <v-card-text>
        <v-alert
          v-if="alert"
          dismissible
          :color="alertColor"
          outlined
        >
          {{ alertMsg }}
        </v-alert>
      </v-card-text>
      <v-card-text>
        <v-form>
          <v-text-field
            v-model="newCertification.nom"
            label="Nom de la certification"
            outlined
            dense
            placeholder="Nom de la certification"
          ></v-text-field>
          <v-row>
            <v-col
              cols="12"
              sm="12"
              class="mb-5"
            >
              <v-select
                v-model="newCertification.formations"
                :items="formations"
                item-text="nom"
                item-value="id"
                :menu-props="{ maxHeight: '400' }"
                label="Formations"
                multiple
                hint="Chosir les formations associées"
                persistent-hint
              ></v-select>
            </v-col>
          </v-row>
          <v-btn
            color="primary"
            @click="createCertification"
          >
            Créer
          </v-btn>

          <v-btn
            type="reset"
            outlined
            class="mx-2"
            @click="reset"
          >
            Réinitialiser
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import axios from 'axios'
import ButtonTopNavigationBack from '../../../components/ButtonTopNavigationBack.vue'

export default {
  components: {
    ButtonTopNavigationBack,
  },
  data() {
    return {
      dialog: false,
      alert: false,
      alertColor: 'success',
      alertMsg: '',
      formations: [],
      newCertification: {
        nom: '',
        formations: [],
      },
      tuteurs: [],
      certifications: [],
    }
  },
  mounted() {
    axios.get(`${process.env.VUE_APP_API_URL}/formations`)
      .then(res => {
        this.formations = res.data
      }).catch(err => {
        console.log(err)
      })
    axios.get(`${process.env.VUE_APP_API_URL}/certifications/${this.$route.params.id}`)
      .then(res => {
        this.newCertification = res.data
      }).catch(err => {
        console.log(err)
      })
  },
  methods: {
    reset() {
      this.newCertification.nom = ''
      this.newCertification.formations = []
    },
    deleteCertification() {
      axios.delete(`${process.env.VUE_APP_API_URL}/certifications/${this.$route.params.id}`)
        .then(() => {
          this.alert = true
          this.alertColor = 'warning'
          this.alertMsg = 'Certification supprimée avec succés :)'
        }).catch(err => {
          console.log(err)
          this.alert = true
          this.alertColor = 'error'
          this.alertMsg = 'Erreur lors de la suppression de la certification :('
        })
    },
    createCertification() {
      axios.post(`${process.env.VUE_APP_API_URL}/certifications`, this.newCertification)
        .then(res => {
          this.newCertification = res.data
          this.alert = true
          this.alertColor = 'success'
          this.alertMsg = 'Certification crée avec succés :)'
        }).catch(err => {
          console.log(err)
          this.alert = true
          this.alertColor = 'error'
          this.alertMsg = 'Erreur lors de la création de la certification :('
        })
    },
  },
}
</script>

<style>

</style>
